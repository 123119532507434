<template>
    <div id="ResetPassword" class="login-form-style">
        <ElForm
            :label-position="store.getters.labelPosition"
            label-width="240px"
            ref="passwordFormRef"
            :model="passwordFormData"
            :rules="passwordFormRules"
        >
            <ElFormItem class="f14 f14-mb" label="パスワード" prop="password">
                <ElInput
                    v-model="passwordFormData.password"
                    type="password"
                ></ElInput>
            </ElFormItem>
            <ElFormItem
                class="f14 f14-mb"
                label="パスワード（確認用）"
                prop="passwordCheck"
            >
                <ElInput
                    v-model="passwordFormData.passwordCheck"
                    type="password"
                ></ElInput>
            </ElFormItem>
            <div class="button-wrap">
                <button class="f18 f16-mb" @click.prevent="submitPasswordForm">
                    パスワードを変更
                </button>
            </div>
        </ElForm>
    </div>
</template>
<script>
export default {
    name: 'ResetPassword',
}
</script>
<script setup>
import { onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { passwordRule, getPasswordCheckRule } from '@/utils/formRules'
import { updatePasswordFromForget, isTokenValid } from '@/service/api.js'
import { ElMessageBox } from 'element-plus'
const store = useStore()
const route = useRoute()
const router = useRouter()

onMounted(async () => {
    let res = await isTokenValid(route.query.token)
    if (!res.data) {
        ElMessageBox.alert(
            'リンクの期限が切れています。もう一度お試してください。',
            'リンクの期限が切れています',
            {
                confirmButtonText: '完了',
                callback: () => {
                    router.push({
                        name: 'ForgetPassword',
                    })
                },
            }
        )
    }
})

const passwordFormRef = ref(null)
const passwordFormData = reactive({
    password: '',
    passwordCheck: '',
})
const passwordFormRules = {
    password: passwordRule,
    passwordCheck: getPasswordCheckRule(passwordFormData),
}
function submitPasswordForm() {
    passwordFormRef.value.validate(async (valid) => {
        if (valid) {
            await updatePasswordFromForget(
                passwordFormData.password,
                route.query.token
            ).then(() => {
                ElMessageBox.alert(
                    '変更後のパスワードで、再度ログインし直してください。',
                    'パスワードを変更しました',
                    {
                        confirmButtonText: '完了',
                        callback: () => {
                            router.push({
                                name: 'MemberSystemLogin',
                            })
                        },
                    }
                )
            })
        }
    })
}
</script>
<style lang="scss" scoped>
@import '@/style/element.scss';
</style>
